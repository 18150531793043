import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import "./notFoundPage.scss";

type Props = {};

export const NotFoundPage = (props: Props) => {
  const navigate = useNavigate();
  const isAuthValue = useSelector(isAuth);

  return (
    <div className="container">
      <p className="notFoundPage__Name">Такой страницы нет&nbsp;:&nbsp;(</p>
      <p className="notFoundPage__text">
        Возможно, что-то пошло не так. Страница либо удалена, либо её не
        существует. Вселенная не хочет, чтобы ты был на этой странице — перейди
        на главную страницу нашей Вселенной
      </p>
      <p className="notFoundPage__img"></p>
      <div className="notFoundPage__edit">
        <p className="notFoundPage__link">Перейти на главную</p>
        <button
          onClick={() => {
            isAuthValue ? navigate("/main") : navigate("/welcome");
          }}
          className="notFoundPage__edit--button"
        ></button>
        <p className="notFoundPage__edit--arr"></p>
      </div>
    </div>
  );
};
