import React from "react";
import "./headerForCollection.scss";

type Props = {};

export const HeaderForCollection = (props: Props) => {
  return (
    <div className="container aboutUs">
      <p className="aboutUs__Center">Суть NFT-Время. Хранить или Похитить?</p>
      <div className="aboutUs__Rigth">
        <p className="aboutUs__Rigth--arrow"></p>
        <p className="aboutUs__Rigth--triangle"></p>
        <p className="aboutUs__Rigth--text">
          Время... Куда ты постоянно бежишь... Порой откроешь глаза, а Солнце
          уже на закате. А иногда чувствуешь себя так необыкновенно, как будто
          Времени совсем не существовало раньше - оно так быстро пролетело, как
          будто его и не было никогда.
        </p>
        <p className="aboutUs__Rigth--span">— Уаджет / 23.12.2022</p>
      </div>
    </div>
  );
};
