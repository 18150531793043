import "./checkboxGroup.scss";
import { ChangeEvent, FC } from "react";

interface CheckboxGroupProps {
  name: string;
  value: string[];
  onChange: (newValue: string[]) => void;
  options: string[];
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  value,
  onChange,
  options,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    const newValue = event.target.checked
      ? [...value, targetValue]
      : value.filter((val) => val !== targetValue);
    onChange(newValue);
  };

  return (
    <div className="checkboxes ">
      <div className="checkboxes--checkboxes-list">
        {options.map((option) => (
          <label key={option}>
            <input
              type="checkbox"
              name={name}
              value={option}
              checked={value.includes(option)}
              //   onChange={(e) => handleChange(e, option)}
              onChange={handleChange}
              className="checkboxes"
            />
            <span className="registration__form__layout__right--checkboxes-list__item--name checkboxes--checkboxes-list--name">
              {option}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
