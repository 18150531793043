import { Link } from "react-router-dom";
import "./welcomePage.scss";
import NavMenu from "../../components/navMenu/NavMenu";
import { useSelector } from "react-redux";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import { Button } from "../../components/UI/button/Button";

export const Welcome = () => {
  const isAuthValue = useSelector(isAuth);
  return (
    <div className="welcome container">
      <img
        className="welcome--photo"
        src="/assets/image/pharaon.png"
        alt="Фото отстутствует"
      />
      <p className="welcome__decor"></p>
      <p className="welcome__figure"></p>

      <div className="welcome__wrapper">
        <div className="welcome__wrapper__description">
          <p className="welcome__wrapper__description--item">
            Метарынок и<br />
            Вселенная по продаже <br />
            NFT токенов
          </p>
        </div>

        <h1 className="welcome__wrapper--title">Вселенная Уаджета</h1>

        {!isAuthValue ? (
          <div className="welcome__wrapper__buttons">
            <Link to="/reg">
              <Button
                className="welcome__wrapper__buttons--button"
                text="Регистрация"
              ></Button>
            </Link>

            <Link to="/login">
              <Button
                className="welcome__wrapper__buttons--button"
                text="Войти"
              ></Button>
            </Link>
          </div>
        ) : (
          <div className="welcome__nav-menu">
            <NavMenu />
          </div>
        )}
      </div>
    </div>
  );
};
