import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ARTICLES_REQUEST,
  articlesFetching,
  articlesSuccess,
  articlesError,
  ARTICLES_POST,
  postArticlesSuccess,
  ARTICLES_LIKE_POST,
  DELETE_ARTICLES_LIKE_POST,
  getLikeArticlesPost,
  GET_ARTICLES_LIKE_POST,
  ARTICLES_LIKE_SUCCESS,
  articlesLikeSuccess,
  ADD_ARTICLES_LIKE_POST,
} from "./Articles.actions";
import {
  deleteLikesArticlesRequest,
  getArticlesRequest,
  getLikesRequest,
  getPostRequest,
  postArticlesRequest,
  postLikesArticlesRequest,
} from "./Articles.services";

function getMappedTitles(acc, value) {
  switch (value.category.id) {
    case 1:
      acc.missions.push(value);
      return acc;
    case 2:
      acc.collections.push(value);
      return acc;
    case 3:
      acc.blog.push(value);
      return acc;
    default:
      return acc;
  }
}

function* patternArticlesRequests(request, payload = {}) {
  try {
    yield put(articlesFetching());
    const titles = yield call(request, payload);
    const mappedTitles = titles.data.reduce(getMappedTitles, {
      missions: [],
      collections: [],
      blog: [],
    });
    yield put(articlesSuccess(mappedTitles));
  } catch (error) {
    yield put(articlesError(error));
  }
}

function* getCommentsLikes(data) {
  try {
    yield put(articlesFetching());
    const like = yield call(getLikesRequest, data.payload);
    yield put(postArticlesSuccess(like));
  } catch (error) {
    yield put(articlesError(error));
  }
}

function* postArticles(data) {
  try {
    yield put(articlesFetching());
    const post = yield call(postArticlesRequest, data.payload);
    yield put(postArticlesSuccess(post));
  } catch (error) {
    yield put(articlesError(error));
  }
}



function* postLikesArticles(data) {
  try {
    yield put(articlesFetching());
    const ANSWER = yield call(postLikesArticlesRequest, data.payload.newLike);
    const post = yield call(getPostRequest, data.payload.post)
    yield put(articlesLikeSuccess(post));
  } catch (error) {
    yield put(articlesError(error));
  }
}

function* deleteLikesArticles(data) {
  try {
    yield put(articlesFetching());
    yield call(deleteLikesArticlesRequest, data.payload.like);
    const post = yield call(getPostRequest, data.payload.post)
    yield put(articlesLikeSuccess(post))
  } catch (error) {
    yield put(articlesError(error));
  }
}





function* getTitles() {
  yield call(patternArticlesRequests, getArticlesRequest);
}

export default function* watchArticlesItems() {
  yield takeEvery(GET_ARTICLES_REQUEST, getTitles);
  yield takeEvery(ARTICLES_LIKE_SUCCESS, articlesLikeSuccess);
  yield takeEvery(ARTICLES_POST, postArticles);
  yield takeEvery(ADD_ARTICLES_LIKE_POST, postLikesArticles);
  yield takeEvery(DELETE_ARTICLES_LIKE_POST, deleteLikesArticles);
}
