import { FC } from "react";
import { Formik, Field, Form } from "formik";
import { Menu } from "../../components/menu/Menu";
import { FormTitle } from "../../components/formTitle/FormTitle";
import { passwordResetRequest } from "../../models/UserAuth/userAuth.services";
import { Button } from "../../components/UI/button/Button";
import "./changePasswordPage.scss";
// import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { RegistrationSchema } from "../../utils/validation/validationSchemes";
// import { userRegistration } from "../../models/UserAuth/userAuth.actions";

type RegustrationUser = {
  email: string;
};

export const ChangePassword: FC = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const initialValues: RegustrationUser = {
    email: "",
  };

  const sendRegistrationRequest = (values: any) => {
    const data = {
      email: values.email,
    };
    passwordResetRequest(data)
      .then((e) => console.log("then", e))
      .catch((e) => console.warn(e));
    // navigate("/main");
  };

  //   if(!tokenparams) return<p>перейдите по ссылке в письме</p>

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(false);
        sendRegistrationRequest(values);
        console.log("values", values);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="changePassword">
            <p className="changePassword__left-decor"></p>
            <Menu />
            <img
              src="/assets/image/auth/reg_pharaon.png"
              className="changePassword--photo"
              alt="Нет изображения"
            />

            <div className="changePassword__form">
              <FormTitle title="Восстановления пароля" />

              <div className="changePassword__form__layout">
                <p className="changePassword__form__layout__left">01 /</p>
                <div className="changePassword__form__layout__middle">
                  <p>Email</p>
                </div>
                <div className="changePassword__form__layout__right">
                  <Field className="changePassword-inputChange" name="email" />
                </div>
              </div>
              <div></div>
              <div className="changePassword__form__layout ">
                <span></span>
                <span></span>
                <div className="changePassword__form__layout__right">
                  <Button className="reg-submit" type="submit">
                    Восстановления пароля
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
