import { FC } from "react";
import { AppRouter } from "../../routes/AppRouter";
import { Provider} from "react-redux";
import store from "../store";
import "./app.scss";

type AppProps = {}

export const App: FC<AppProps> = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};