import { FC } from "react";
import { Menu } from "../menu/Menu";
import { Formik, Field, Form } from "formik";
import { FormTitle } from "../formTitle/FormTitle";
import "./registrationForm.scss";
import { RegistrationSchema } from "../../utils/validation/validationSchemes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userRegistration } from "../../models/UserAuth/userAuth.actions";
import { Button } from "../UI/button/Button";
import { isFetching } from "../../models/UserAuth/userAuth.selectors";
import { Loader } from "../UI/loader/Loader";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";

type RegustrationUser = {
  lastname: string;
  firstname: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
  wallet: [];
  about: string;
  notification: string;
  agree: [];
  validateOnMount: boolean;
};

export const RegistrationForm: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetching = useSelector(isFetching);

  const initialValues: RegustrationUser = {
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    password: "",
    passwordConfirm: "",
    wallet: [],
    about: "",
    notification: "",
    agree: [],
    validateOnMount: true,
  };

  const sendRegistrationRequest = (values: any) => {
    const checkedWallets = document.querySelectorAll(
      'input[name="wallet"]:checked'
    );
    const walletArray = Array.from(checkedWallets).map(
      (checkbox: any) => checkbox.value
    );
    const cleanedWallet = walletArray.join(", ");
    const data = {
      firstname: values.firstname,
      password: values.password,
      email: values.email,
      lastname: values.lastname,
      phone: values.phone,
      about: values.about,
      wallet: cleanedWallet,
    };
    dispatch(userRegistration(data));
    navigate("/main");
  };

  if (fetching) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={RegistrationSchema}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(false);
        sendRegistrationRequest(values);
      }}
    >
      {({ errors, touched, isValid, dirty, values, setFieldValue }) => {
        const formIsValid =
          values.password === values.passwordConfirm && isValid;
        return (
          <Form>
            <div className="registration container">
              <p className="registration__left-decor"></p>
              <Menu />
              <img
                src="/assets/image/auth/reg_pharaon.png"
                className="registration--photo"
                alt="picture"
              />
              <img src="/assets/image/minReg.png" className="registration--photoMobile"/>

              <div className="registration__form">
                <div className="registration__form--head">
                  <FormTitle title="РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ" />
                </div>
                <div className="registration__form__layout">
                  <p className="registration__form__layout__left">01 /</p>

                  <div className="registration__form__layout__right">
                    <p className="registration__form__layout__middle">
                      Фамилия
                    </p>
                    <Field className="registration-inputReg" name="lastname" />

                    <p className="registration__form__layout__middle">Имя</p>
                    <Field className="registration-inputReg" name="firstname" />

                    <p className="registration__form__layout__middle">
                      Телефон
                    </p>
                    <Field
                      className="registration-inputReg"
                      type="number"
                      name="phone"
                    />

                    <p className="registration__form__layout__middle">Email</p>
                    <Field className="registration-inputReg" name="email" />

                    <p className="registration__form__layout__middle">Пароль</p>
                    <Field className="registration-inputReg" name="password" />

                    <p className="registration__form__layout__middle">
                      Подтверждение пароля
                    </p>
                    <Field
                      className="registration-inputReg"
                      name="passwordConfirm"
                    />
                  </div>

                  <div className="registration__form__layout__validate">
                    {errors.lastname && touched.lastname ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.lastname}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}

                    {errors.firstname && touched.firstname ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.firstname}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}

                    {errors.phone && touched.phone ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.phone}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}

                    {errors.email && touched.email ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.email}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}

                    {errors.password && touched.password ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.password}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}

                    {errors.passwordConfirm || touched.passwordConfirm ? (
                      <div className="registration__form__layout__validate--alert">
                        {errors.passwordConfirm}
                      </div>
                    ) : (
                      <div className="registration__form__layout__validate--alert">
                        &nbsp;
                      </div>
                    )}
                  </div>
                </div>

                <div className="registration__form__layout">
                  <p className="registration__form__layout__left">02 /</p>
                  <div className="registration__form__layout__middle">
                    <span className="registration__form__layout__middle--note">
                      Необязательное поле
                    </span>
                  </div>

                  <div className="registration__form__layout__right">
                    <span className="text2">
                      Выбериете кошельки, которые вы планируете использоввать.
                      Если затрудняетесь, можете оставить поле пустым.
                    </span>
                  </div>
                  <div className="registration__form__layout__right--checkboxes-list">
                    <CheckboxGroup
                      name="wallet"
                      value={values.wallet}
                      onChange={(newValue: string[]) =>
                        setFieldValue("wallet", newValue)
                      }
                      options={[
                        "Bitcoin BTC",
                        "XRP XRP",
                        "BNB BNB",
                        "Litecoin LTC",
                        "Ethereum ETH",
                        "Cardano ADA",
                        "Zcash ZEC",
                      ]}
                    />
                  </div>
                </div>
                <div className="registration__form__layout">
                  <p className="registration__form__layout__left">03 /</p>
                  <span></span>
                  <div className="registration__form__layout__right">
                    <p>Описание профиля</p>
                    <Field
                      as="textarea"
                      className="registration__form__layout__right reg-text-area "
                      name="about"
                      placeholder="Пару слов о себе..."
                    />
                  </div>
                </div>

                <div className="registration__form__layout">
                  <span className="registration__form__layout__left">04 /</span>
                  <span className="registration__form__layout__middle"></span>

                  <div className="registration__form__layout__right__notifications ">
                    <p className="notification">
                      Хотите получать уведомления о транзакциях?
                    </p>
                    <div className="registration__form__layout__right__notifications__radio-btns">
                      <label>
                        <Field type="radio" name="notification" value="later" />
                        &nbsp;&nbsp;&nbsp;Решить потом
                      </label>

                      <label>
                        <Field type="radio" name="notification" value="yes" />
                        &nbsp;&nbsp;&nbsp;Да
                      </label>

                      <label>
                        <Field type="radio" name="notification" value="no" />
                        &nbsp;&nbsp;&nbsp;Нет
                      </label>
                    </div>
                  </div>
                </div>

                <div className="registration__form__layout ">
                  <span className="bord"></span>
                  <div className="registration__form__layout__middle">
                    <span className="registration__form__layout__middle--note2">
                      Обязательное поле
                    </span>
                  </div>
                  <div className="registration__form__layout__right">
                    <div className="registration__form__layout__right--checkboxes-list__item">
                      <label>
                        <Field
                          className="checkbox"
                          type="checkbox"
                          name="agree"
                          value="agree-yes"
                        />
                        <span className="registration__form__layout__right--checkboxes-list__item--name nameBottom">
                          Согласен с условиями пользовательского соглашения. Даю
                          согласие на обработку своих персональных данных.
                        </span>
                      </label>
                    </div>
                    <div className="registration__form__layout__right--checkboxes-list__item">
                      <label>
                        <Field
                          className="checkbox"
                          type="checkbox"
                          name="agree2"
                          value="agree-read"
                        />
                        <span className="registration__form__layout__right--checkboxes-list__item--name nameBottom">
                          Я прочитал политику конфедициальности и согласен с её
                          условиями сервиса по обработке персональных данных.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="registration__form__layout ">
                  <span></span>
                  <span></span>
                  <div className="registration__form__layout__right">
                    <Button
                      className="reg-submit reg-button"
                      type="submit"
                      disabled={!formIsValid}
                      text="Регистрация профиля"
                      submit
                    ></Button>
                    <p className="reg-submit--arr"></p>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
