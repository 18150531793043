import { LoginForm } from "../components/loginForm/LoginForm";
import { Welcome } from "../pages/welcomePage/WelcomePage";
import {
  PrivateRoute,
  PrivateRoutesNames,
  PublicRoute,
  PublicRoutesNames,
} from "./routes.types";
import { MainPage } from "../pages/mainPage/MainPage";
import { RegistrationForm } from "../components/registationForm/RegistrationForm";
import { ProfilePage } from "../pages/profilePage/ProfilePage";
import { CollectionPage } from "../pages/collectionPage/CollectionPage";
import { ChangeProfilePage } from "../pages/changeProfilePage/ChangeProfilePage";
import { ChangePassword } from "../pages/changePasswordPage/ChangePasswordPage";
import { SetNewPasswordPage } from "../pages/setNewPassword/SetNewPasswordPage";
import { NotFoundPage } from "../pages/notFoundPage/NotFoundPage";
import { SelectCardPage } from "../pages/selectCardPage/SelectCardPage";
import { TestCollectionPage } from "../pages/collectionPage/TestCollectionPage";

export const publicRoutes: PublicRoute[] = [
  { path: PublicRoutesNames.HOME_UNSIGN, element: Welcome },
  { path: PublicRoutesNames.REG, element: RegistrationForm },
  { path: PublicRoutesNames.LOGIN, element: LoginForm },
  { path: PublicRoutesNames.RESTOREPASS, element: ChangePassword },
  { path: PublicRoutesNames.REGNEWPASS, element: SetNewPasswordPage },
  { path: PublicRoutesNames.NOTFOUND, element: NotFoundPage },
  { path: PublicRoutesNames.MAIN, element: MainPage },
  { path: PublicRoutesNames.MISSIONS, element: SelectCardPage },
  { path: PublicRoutesNames.COLLECTPAGE, element: SelectCardPage },
  { path: PublicRoutesNames.BLOG, element: SelectCardPage },
  { path: PublicRoutesNames.COLLECTION, element: CollectionPage },
];

export const privateRoutes: PrivateRoute[] = [
  { path: PrivateRoutesNames.HOME, element: Welcome },
  { path: PrivateRoutesNames.COLLECTION, element: CollectionPage },
  { path: PrivateRoutesNames.PROFILE, element: ProfilePage },
  { path: PrivateRoutesNames.MAIN, element: MainPage },
  { path: PrivateRoutesNames.CHANGE, element: ChangeProfilePage },
  { path: PrivateRoutesNames.NOTFOUND, element: NotFoundPage },
  { path: PrivateRoutesNames.MISSIONS, element: SelectCardPage },
  { path: PrivateRoutesNames.COLLECTPAGE, element: SelectCardPage },
  { path: PrivateRoutesNames.BLOG, element: SelectCardPage },
  { path: PrivateRoutesNames.TEST, element: TestCollectionPage },
];
