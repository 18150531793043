import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import {
  userLoginSuccess,
  userLogouted,
} from "../models/UserAuth/userAuth.actions";
import { isAuth } from "../models/UserAuth/userAuth.selectors";
import { Welcome } from "../pages/welcomePage/WelcomePage";
import { getCookie } from "../utils/cookie";
import { privateRoutes, publicRoutes } from "./routersMap";
import './appRouter.scss'

export const AppRouter: FC = () => {
  const location = useLocation(); // Hook from react-router v6
  const isAuthValue = useSelector(isAuth);
  const routes = isAuthValue ? privateRoutes : publicRoutes;
  const refreshToken = getCookie("refreshToken");

  const dispatch = useDispatch();

  useEffect(() => {
    if (refreshToken) {
      dispatch(userLoginSuccess());
    } else {
      dispatch(userLogouted());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className="main_container">
      <Header />
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            element={<route.element />}
            key={route.path}
          ></Route>
        ))}
        <Route path="*" element={<Welcome />} />
      </Routes>
      <Footer />
    </div>
  );
};