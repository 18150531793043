import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_USER_INFO_REQUEST,
  UPDATE_USER_INFO_REQUEST,
  userInfoFetching,
  userInfoSuccess,
  userInfoError,
} from "./userInfo.actions";
import { getUserInfoRequest, updateUserInfoRequest } from "./userInfo.services";

function* patternUserInfoRequests(request, payload = {}) {
  try {
    yield put(userInfoFetching());
    const userInfoData = yield call(request, payload);
    yield put(userInfoSuccess(userInfoData.data));
  } catch (error) {
    yield put(userInfoError(error));
  }
}

function* getInfo() {
  yield call(patternUserInfoRequests, getUserInfoRequest);
}

// function* updateInfo(data) {
//   yield call(patternUserInfoRequests, updateLibrariesItemsRequest, data.payload);
// }



export default function* watchUserInfoData() {
  yield takeEvery(GET_USER_INFO_REQUEST, getInfo);
  //   yield takeEvery(UPDATE_USER_INFO_REQUEST, updateInfo);
}
