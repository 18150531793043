import React from "react";
import "./headerForAuth.scss";

type Props = {};

export const HeaderForAuth = (props: Props) => {
  return (
    <div className="aboutUs">
      <p className="aboutUs__Center">
        Метарынок и Вселенная по продаже NFT токенов
      </p>
      <div className="aboutUs__Rigth">
        <p className="aboutUs__Rigth--arrow"></p>
        <p className="aboutUs__Rigth--triangle"></p>
        <p className="aboutUs__Rigth--text">
          Смотрю на мир сквозь призму коэффициентов Золотого сечения. Движение
          цен биткоина и разных криптовалютных инструментов с помощью Волнового
          принципа Эллиотта с целью оседлать Мечту Волновика!
        </p>
        <p className="aboutUs__Rigth--span">— Уаджет / 23.12.2022</p>
      </div>
    </div>
  );
};
