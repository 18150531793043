import { FC } from "react";
import "./footer.scss";

export const Footer: FC = () => {
  return (
    <div className="footer container">
      <p className="footer__bord"></p>
      <p className="footer__imgCorner"></p>
      <p className="footer__icon"></p>
      <ul className="footer__cols">
        <div className="footer__cols__left">
          <li className="footer__cols__left--info">
            © Вселенная Уаджета / 18+
          </li>
          <li className="footer__cols__left--year">/ 2023</li>
        </div>
        <div className="footer__cols__right">
          <li className="footer__cols__right--eternity">
            Метарынок и Вселенная по продаже NFT токенов
          </li>
          <li className="footer__cols__right--eight">18+</li>
        </div>
      </ul>
    </div>
  );
};
