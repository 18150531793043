import { createSelector } from "reselect";

const articlesPosts = (state) => state.articlesReducer;

export const articlesItems = createSelector(
  articlesPosts,
  (state) => state.data
);

export const getFiltredArticlesItems = (id, slug) =>
  createSelector(articlesItems, (data) => {
    return data[slug]?.filter((v) => v.id.toString() === id.toString())[0] || {};
  });

export const isFetching = createSelector(
  articlesPosts,
  (state) => state.fetching
);


export const getArticlesPosts = createSelector(
  articlesPosts,
  (state) => state.data
);
