import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderForAuth } from "../../components/headerForAuth/HeaderForAuth";
import { articlesItems } from "../../models/Articles/Articles.selectors";
import { getArticlesRequest } from "../../models/Articles/Articles.actions";
import NavMenu from "../../components/navMenu/NavMenu";
import { FooterNav } from "../../components/FooterNav/FooterNav";
import { useNavigate } from "react-router-dom";
import { userInfoDataValues } from "../../models/UserInfo/userInfo.selectors";
import "./profilePage.scss";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import { getUserInfoRequest } from "../../models/UserInfo/userInfo.actions";

export const userData = () => {};

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const articlesItemsData = useSelector(articlesItems);
  const userDataValue = useSelector(userInfoDataValues);
  const isAuthValue = useSelector(isAuth);
  // const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getArticlesRequest());
    // api.get("/users", {}).then((e) => setUserData(e));
    if (isAuthValue) {
      dispatch(getUserInfoRequest());
    }
  }, [isAuthValue, dispatch]);

  if (!(userDataValue && articlesItemsData)) return null;

  return (
    <div className="div">
      <HeaderForAuth />
      <NavMenu />
      <div className="wallet">
        <p className="wallet__triangle"></p>
        <p className="wallet__figure"></p>

        <div className="wallet__about">
          <div className="wallet__about__text">
            <p className="wallet__about__text--date">
              Зарегистрирован: 23.12.2022
            </p>
            <p className="wallet__about__text--link">{userDataValue?.email}</p>
          </div>

          <p className="wallet__about__name">{userDataValue?.firstname}</p>
          <div className="wallet__about__name"> {userDataValue?.lastname}</div>
          <div className="wallet__about__edit">
            <p className="wallet__about__edit--text">Редактировать профиль</p>
            <button
              onClick={() => navigate("/changeuser")}
              className="wallet__about__edit--button"
            ></button>
            <p className="wallet__about__edit--arr"></p>
          </div>
        </div>

        <div className="wallet__purse">
          <div className="wallet__purse__info">
            <button className="wallet__purse__info--button">История</button>
            <p className="wallet__purse__info--picEye"></p>
            <p className="wallet__purse__info--picWit"></p>
            <p className="wallet__purse__info--picGrey"></p>
            <p className="wallet__purse__info--picDark"></p>
          </div>

          <hr className="wallet__purse--line"></hr>
          <div className="wallet__purse__add">
            <div className="wallet__purse__add__balance">
              <p className="wallet__purse__add__balance--text">
                Текущий баланс
              </p>
              <p className="wallet__purse__add__balance--money">
                {userDataValue?.balance}
              </p>
            </div>

            <div className="wallet__purse__add__currency">
              <p className="wallet__purse__add__currency--curse">Валюта</p>
              <p className="wallet__purse__add__currency--ytj">YJT</p>
            </div>
          </div>
        </div>
      </div>
      <FooterNav />
    </div>
  );
};
