/* eslint-disable */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  USER_LOGIN,
  USER_LOGOUT,
  userLoginFetching,
  userLoginSuccess,
  userLoginError,
  userLogouted,
  USER_REGISTRATION,
  USER_REFRESH,
  USER_CHANGE,
  changedUser,
} from "./userAuth.actions";
import {
  loginRequest,
  registrationRequest,
  logoutRequest,
  refreshRequest,
  changeRequest,
} from "./userAuth.services";
import { setCookie, deleteCookie } from "../../utils/cookie";

function* userLogin(userData) {
  try {
    yield put(userLoginFetching());
    const { data } = yield call(loginRequest, userData.payload);

    setCookie("accessToken", data.token);
    setCookie("refreshToken", data.refresh_token);
    yield put(userLoginSuccess());
  } catch (error) {
    yield put(userLoginError(console.log(error)));
  }
}

function* userRegistration(userData) {
  try {
    yield put(userLoginFetching());
    const { data } = yield call(registrationRequest, userData.payload);
    setCookie("accessToken", data.token);
    yield put(userLoginSuccess());
  } catch (error) {
    yield put(userLoginError(error));
  }
}



function* userChange(userData) {
  try {
    yield put(userLoginFetching());
    yield call(changeRequest, userData.payload);
    yield put(changedUser());
  } catch (error) {
    yield put(userLoginError(error));
  }
}


function* userRefresh(userData) {
  try {
    yield put(userLoginFetching());
    const { data } = yield call(refreshRequest, userData.payload);
    setCookie("accessToken", data.accessToken);
    setCookie("refreshToken", data.refreshToken);
    yield put(userLoginSuccess());
  } catch (error) {
    yield put(userLoginError(error));
  }
}

function* userLogout() {
  try {
    yield put(userLoginFetching());
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    yield put(userLogouted());
  } catch (error) {
    yield put(userLoginError(error));
  }
}

export default function* watchUserAuth() {
  yield takeEvery(USER_LOGIN, userLogin);
  yield takeEvery(USER_REGISTRATION, userRegistration);
  yield takeEvery(USER_REFRESH, userRefresh);
  yield takeEvery(USER_LOGOUT, userLogout);
  yield takeEvery(USER_CHANGE, userChange);
}
