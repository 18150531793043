import {
  USER_LOGOUTED,
  USER_LOGIN_FETCHING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_CHANGED,
} from "./userAuth.actions";

const initialState = {
  isAuth: false,
  fetching: false,
  error: "",
};

export const userAuth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUTED:
      return {
        ...state,
        isAuth: false,
        fetching: false,
      };

    case USER_LOGIN_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case USER_CHANGED:
      return {
        ...state,
        fetching: false,
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        fetching: false,
      };

    case USER_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    default:
      return state;
  }
};
