import { HeaderForAuth } from "../../components/headerForAuth/HeaderForAuth";
import NavMenu from "../../components/navMenu/NavMenu";
import { ArticlesCard } from "../../components/ArticlesCard/ArticlesCard";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getArticlesRequest } from "../../models/Articles/Articles.actions";
import {
  articlesItems,
  isFetching,
} from "../../models/Articles/Articles.selectors";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import { Loader } from "../../components/UI/loader/Loader";
import { ArticleSmallCards } from "../../components/articleSmallCards/ArticleSmallCards";
import "./selectCardPage.scss";

export const SelectCardPage = () => {
  const isAuthValue = useSelector(isAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetching = useSelector(isFetching);
  const articlesItemsData = useSelector(articlesItems);
  const [activeDiv, setActiveDiv] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // if (isAuthValue) {
        await dispatch(getArticlesRequest());
      // }
    };
    fetchData();
  }, [isAuthValue, dispatch, location.pathname]);
  
  const setActiveDivByLocation = useCallback(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/missions":
        return 3;
      case "/blog":
        return 4;
      case "/collections":
        return 5;
      default:
        return null;
    }
  }, [location.pathname]);

  const handleLinkClick = (id) => {
    setActiveDiv(id);
  };

  useEffect(() => {
    setActiveDiv(setActiveDivByLocation());
  }, [setActiveDivByLocation]);

  if (fetching) {
    return <Loader />;
  }



  return (
    <div className="container">
      <HeaderForAuth />
      <NavMenu onLinkClick={handleLinkClick} />
      <div className="block__mini div">
        <div className="block__mini__nameBlock">
          <p className="block__mini__nameBlock--new">Новинки</p>
          <p className="block__mini__nameBlock--little">
            Смотреть все новые миссии
          </p>
          <div className="block__mini__nameBlock__press">
            <button className="block__mini__nameBlock__press--button"></button>
            <p className="block__mini__nameBlock__press--arr"></p>
          </div>
        </div>
        {activeDiv === 3 && (
          <div className="block__square">
            {articlesItemsData.missions?.map((item, index) => {
              const hasPathAndUrl =
                item?.thumbnail_base_url && item?.thumbnail_path;
              const srcUrl = hasPathAndUrl
                ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
              return (
                <ArticleSmallCards
                  target="_blank"
                  src={srcUrl}
                  key={item.id}
                  title={item.title}
                  body={item.body}
                  date={item.created_at}
                  difficulty={3}
                  onClick={() => {
                    navigate(
                      `/collection/${item.id}/${item.category.slug}`,
                      {}
                    );
                  }}
                />
              );
            })}
          </div>
        )}

        {activeDiv === 4 && (
          <div className="block">
            <div className="block__square">
              {articlesItemsData.blog?.map((item, index) => {
                const hasPathAndUrl =
                  item?.thumbnail_base_url && item?.thumbnail_path;
                const srcUrl = hasPathAndUrl
                  ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
                return (
                  <ArticleSmallCards
                    src={srcUrl}
                    key={item.id}
                    title={item.title}
                    body={item.body}
                    date={item.created_at}
                    onClick={() => {
                      navigate(
                        `/collection/${item.id}/${item.category.slug}`,
                        {}
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}

        {activeDiv === 5 && (
          <div className="block">
            <div className="block__square">
              {articlesItemsData.collections?.map((item, index) => {
                const hasPathAndUrl =
                  item?.thumbnail_base_url && item?.thumbnail_path;
                const srcUrl = hasPathAndUrl
                  ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
                return (
                  <ArticlesCard
                    src={srcUrl}
                    key={item.id}
                    title={item.title}
                    body={item.body}
                    date={item.created_at}
                    onClick={() => {
                      navigate(
                        `/collection/${item.id}/${item.category.slug}`,
                        {}
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
