import React, { useEffect, useState } from "react";
import Comment from "./Comment";
import "./CommentSection.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  isFetching,
  userInfoDataValues,
} from "../../models/UserInfo/userInfo.selectors";
import {
  getArticlesRequest,
  postArticles,
} from "../../models/Articles/Articles.actions";
import { Loader } from "../UI/loader/Loader";
import { getFiltredArticlesItems } from "../../models/Articles/Articles.selectors";
import { useParams } from "react-router-dom";

interface CommentData {
  // id: number;
  text: string;
  created_at: number;
  user: { username: string; email: string };
  article_id: number;
}

interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  user_id: any;
}

interface CommentSectionProps {
  data: any;
  userName: string;
}

export const CommentSection: React.FC<CommentSectionProps> = ({ data }) => {
  const userDataValue = useSelector(userInfoDataValues);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true);
  const dispatch = useDispatch();
  const fetching = useSelector(isFetching);

  const comments = data.posts;

  useEffect(() => {
    dispatch(getArticlesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (userDataValue && !userData) {
      setUserData(userDataValue);
      setLoadingUserData(false);
    } else if (!userDataValue) {
      setLoadingUserData(true);
    }
  }, [userDataValue]);

  const handleAddComment = async (text: string) => {
    if (!userData) {
      return;
    }
    const userName = userData.firstname + " " + userData.lastname;
    const newComment: CommentData = {
      text,
      user: {
        username: userName,
        email: userData.email,
      },
      article_id: data.id,
      created_at: Math.floor(new Date().getTime() / 1000),
    };
    dispatch(postArticles(newComment));
  };

  if (fetching) {
    return <Loader />;
  }

  return (
    <div className="commentSection">
      
      <h3>Комментарии пользователей</h3>
      <h6>Оставьте свой комментарий. Не используйте нецензурную лексику.</h6>
      {userDataValue ? (
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const target = e.target as typeof e.target & {
              0: { value: string };
              1: { value: string };
            };
            const text = target[0].value;
            handleAddComment(text);
            e.currentTarget.reset();
          }}
        >
          <textarea placeholder="Текст комментария" required />
          <button className="buttonClip" type="submit"></button>
          <button className="buttonSend" type="submit">
            Отправить
          </button>
        </form>
      ) : (
        <p>Войдите в аккаунт, чтобы оставлять комментарии.</p>
      )}
      {comments?.length > 0 && (
        <div className="comments">
          {comments.map((comment: any, index: any) => (
            <Comment
              comment={comment}
              userId={userData?.user_id}
              data={data}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};
