import { createSelector } from "reselect";

const userInfoData = (state) => {
    return state.userInfoData

};

export const userInfoDataValues = createSelector(
  userInfoData,
  (state) => state.data
);
export const isFetching = createSelector(
  userInfoData,
  (state) => state.fetching
);

