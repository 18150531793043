import "./loader.scss";
import React from "react";

type LoaderProps = {};

export const Loader: React.FC<LoaderProps> = () => (
  <div className="loaderWrapper">
    <div className="loader" />
  </div>
);
