import { useEffect } from "react";
import { HeaderForAuth } from "../../components/headerForAuth/HeaderForAuth";
import { getArticlesRequest } from "../../models/Articles/Articles.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  articlesItems,
  isFetching,
} from "../../models/Articles/Articles.selectors";
import { ArticlesCard } from "../../components/ArticlesCard/ArticlesCard";
import { useNavigate } from "react-router-dom";
import NavMenu from "../../components/navMenu/NavMenu";
import { FooterNav } from "../../components/FooterNav/FooterNav";
import "./mainPage.scss";
import { Loader } from "../../components/UI/loader/Loader";
import { Button } from "../../components/UI/button/Button";

export const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const articlesItemsData = useSelector(articlesItems);
  const fetching = useSelector(isFetching);

  useEffect(() => {
    dispatch(getArticlesRequest());
  }, [dispatch]);

  if (fetching) {
    return <Loader />;
  }

  return (
    <div className="container">
      <HeaderForAuth />
      <NavMenu />
      <div className="buttons">
        <Button className="buttons__buttonBase" text="БАЗА ЗНАНИЙ"></Button>
        <Button className="buttons__buttonBots" text="НАШИ БОТЫ"></Button>
      </div>
      <div className="mission">
        <p className="mission__Triangle"></p>
        <p className="mission__Name">ВСЕЛЕННАЯ УАДЖЕТА</p>
        <p className="mission__Figure"></p>
      </div>

      <div className="block">
        <div className="block__name">
          <p className="block__name--text">Миссии</p>
        </div>

        <div className="block__square">
          {articlesItemsData.missions?.map((item, index) => {
            const hasPathAndUrl =
              item?.thumbnail_base_url && item?.thumbnail_path;
            const srcUrl = hasPathAndUrl
              ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
            return (
              <ArticlesCard
                target="_blank"
                src={srcUrl}
                key={item.id}
                title={item.title}
                body={item.body}
                date={item.created_at}
                onClick={() => {
                  navigate(`/collection/${item.id}/${item.category.slug}`, {});
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="block">
        <div className="block__name">
          <p className="block__name--text">Коллекции</p>
          <p className="block__name--pic"></p>
        </div>
        <div className="block__square">
          {articlesItemsData.collections?.map((item, index) => {
            const hasPathAndUrl =
              item?.thumbnail_base_url && item?.thumbnail_path;
            const srcUrl = hasPathAndUrl
              ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
            return (
              <ArticlesCard
                src={srcUrl}
                key={item.id}
                title={item.title}
                body={item.body}
                date={item.created_at}
                onClick={() => {
                  navigate(`/collection/${item.id}/${item.category.slug}`, {});
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="block">
        <div className="block__name">
          <p className="block__name--text">блог</p>
          <p className="block__name--pic"></p>
        </div>
        <div className="block__square">
          {articlesItemsData.blog?.map((item, index) => {
            const hasPathAndUrl =
              item?.thumbnail_base_url && item?.thumbnail_path;
            const srcUrl = hasPathAndUrl
              ? item?.thumbnail_base_url + "/" + item?.thumbnail_path
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSki_60n7oWPjBapzIgkTOh-ue44_v3kBaerQ&usqp=CAU";
            return (
              <ArticlesCard
                src={srcUrl}
                key={item.id}
                title={item.title}
                body={item.body}
                date={item.created_at}
                onClick={() => {
                  navigate(`/collection/${item.id}/${item.category.slug}`, {});
                }}
              />
            );
          })}
        </div>
      </div>

      <p className="centerPic"></p>
      <div className="main">
        <div className="main__Head">
          <p className="main__Head--head">НАШИ БОТЫ </p>
          <p className="main__Head--pic"></p>
        </div>
        <div className="main__Body">
          <div className="main__Body__text">
            <p className="main__Body__text--small">Боты</p>
            <p className="main__Body__text--minus">/</p>
            <p className="main__Body__text--big">
              Подпишитесь на наших основных ботов, чтобы начать погружение в
              метавселенную
            </p>
          </div>
          <ul className="main__Body__list">
            <div className="main__Body__list__Left">
              <li className="main__Body__list__Left--li">01</li>
              <li className="main__Body__list__Left--li">02</li>
              <li className="main__Body__list__Left--li">03</li>
            </div>
            <div className="main__Body__list__Center">
              <li className="main__Body__list__Center--li">/</li>
              <li className="main__Body__list__Center--li">/</li>
              <li className="main__Body__list__Center--li">/</li>
            </div>

            <div className="main__Body__list__Right">
              <li className="main__Body__list__Right--li">
                Как найти нужного бота?
              </li>
              <li className="main__Body__list__Right--li">Начинаем покупать</li>
              <li className="main__Body__list__Right--li">
                Подключение кошелька
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div className="users">
        <div className="users__Card">
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic"></p>
            <p className="users__Card__Item--text">@earnyadg_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic--2"></p>
            <p className="users__Card__Item--text">@yadgetgame_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic--3"></p>
            <p className="users__Card__Item--text">@yadghelperbot</p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic--4"></p>
            <p className="users__Card__Item--text">@yadgetmetaverse_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic--5"></p>
            <p className="users__Card__Item--text">@Zdorovik_bot</p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic"></p>
            <p className="users__Card__Item--text">@Yadget_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">t.me/yadgetTelegram</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic"></p>
            <p className="users__Card__Item--text">@Yadgetbook_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
          <div className="users__Card__Item">
            <p className="users__Card__Item--pic"></p>
            <p className="users__Card__Item--text">@Volnovik_bot </p>
            <hr className="users__Card__Item--line"></hr>
            <p className="users__Card__Item--link">Ссылка ТГ</p>
            <button className="users__Card__Item--button"></button>
            <p className="users__Card__Item--arr"></p>
          </div>
        </div>
      </div>
      <span className="bord"></span>
      <FooterNav />
    </div>
  );
};
