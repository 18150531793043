import { api } from "../../core/api";

export const getArticlesRequest = async () => api.get("/articles");
export const getLikesRequest = async () => api.get("/likes");

export const getPostRequest = async (id) => api.get(`/posts/${id}`);


export const postArticlesRequest = async data => api.post("/posts", data);


export const postLikesArticlesRequest = async data => api.post("/likes", data);

export const deleteLikesArticlesRequest = async id => api.delete(`/likes/${id}`);


/* 
Это отпраляю:
{


}

Это получаю:
{


}

*/ 