export const USER_LOGIN = "USER_LOGIN";
export const USER_REFRESH = "USER_REFRESH";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUTED = "USER_LOGOUTED";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const USER_LOGIN_FETCHING = "USER_LOGIN_FETCHING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_CHANGE = "USER_CHANGE";
export const USER_CHANGED = "USER_CHANGED";

export const userLogin = (data) => ({
  type: USER_LOGIN,
  payload: data,
});

export const changeUser = (data) => ({
  type: USER_CHANGE,
  payload: data,
});

export const changedUser = (data) => ({
  type: USER_CHANGED,
  payload: data,
});

export const userRefresh = (data) => ({
  type: USER_REFRESH,
  payload: data,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const userLogouted = () => ({
  type: USER_LOGOUTED,
});

export const userRegistration = (data) => ({
  type: USER_REGISTRATION,
  payload: data,
});

export const userLoginFetching = () => ({
  type: USER_LOGIN_FETCHING,
});

export const userLoginSuccess = () => ({
  type: USER_LOGIN_SUCCESS,
});

export const userLoginError = (error) => ({
  type: USER_LOGIN_ERROR,
  payload: error,
});
