import { FC } from "react";
import { Link } from "react-router-dom";
import "./menu.scss";

export const Menu: FC = () => {
  return (
    <div className="auth-menu">
      <p className="eye"></p>
      <p className="blackCircle"></p>
      <Link className="auth-menu--item" to="/reg">
        РЕГИСТРАЦИЯ
      </Link>
      <Link className="auth-menu--item" to="/login">
        ВОЙТИ
      </Link>
    </div>
  );
};
