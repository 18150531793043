import { memo, FC } from "react";
import Moment from "react-moment";

const defaultPic= 'https://flyclipart.com/thumbs/goat-simulator-ios-all-goats-967802.png'
type ArticlesCardTypes = {
  src: string;
  onClick: () => void;
  title:string;
  date:string;
};

export const ArticlesCard: FC<ArticlesCardTypes> = memo(({ src=defaultPic, onClick,title,date }) => {
  return (
      <div className="block__square__cards">
        <img
          role="presentation"
          className={"block__square__cards--img"}
          onClick={onClick}
          src={src}
          alt=""
        />
        <div className="block__square__cards--group">
          <div className="block__square__cards--group--el1">
            <p className="block__square__cards--group--el1--head">
              /Руководство
            </p>
            <p className="block__square__cards--group--el1--data">
              <Moment unix format="DD.MM.YYYY">{date}</Moment>
            </p>
          </div>
          <div className="block__square__cards--group--el2">
            <p className="block__square__cards--group--el2--text" onClick={onClick}>
              {title}
            </p>
            <p className="block__square__cards--group--el2--arrow" onClick={onClick}></p>
          </div>
        </div>
      </div>
  );
});