import { useState, useEffect } from "react";
import { HeaderForCollection } from "../../components/headerForCollection/HeaderForCollection";
import { useParams } from "react-router-dom";
import NavMenu from "../../components/navMenu/NavMenu";
import { useDispatch, useSelector } from "react-redux";
import { articlesItems } from "../../models/Articles/Articles.selectors";
import { FooterNav } from "../../components/FooterNav/FooterNav";
import { getArticlesRequest } from "../../models/Articles/Articles.actions";
import Moment from "react-moment";
import "./collectionPage.scss";
import HTMLContent from "./HTMLContent";

export const TestCollectionPage = () => {
  const [data, setData] = useState({});
  const { id, slug } = useParams();
  const articlesItemsData = useSelector(articlesItems);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticlesRequest());
  }, [dispatch]);

  useEffect(() => {
    setData(
      articlesItemsData[slug]?.filter(
        (v) => v.id.toString() === id.toString()
      )[0] || {}
    );
  }, [id, slug, articlesItemsData]);

  const hasPathAndUrl = data?.thumbnail_base_url && data?.thumbnail_path;
  const srcUrl = hasPathAndUrl
    ? data?.thumbnail_base_url + "/" + data?.thumbnail_path
    : "";


  return (
    <div className="">
      <HeaderForCollection />
      <NavMenu />
      <div className="description">
        <div className="description__header">
          <p className="description__header--text">{data.title}</p>
        </div>

        <div className="description__image">
          <figure>
            <img
              src={srcUrl}
              alt=""
              className="description__image--img img-fluid"
            />
            <figcaption>
              {data.external_link_title} -{" "}
              <a
                className="link_coll"
                href={`${data.external_link_url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.external_link_url}
              </a>
            </figcaption>
          </figure>
          <link rel="stylesheet" href="" />

          <div className="description__image--textBox"></div>
          <HTMLContent />
        </div>
      </div>
      <FooterNav />
    </div>
  );
};
