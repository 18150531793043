 import React, { ChangeEvent, useRef, useState } from 'react';
import './imageUploader.scss';

type ImageUploaderProps = {
  onUpload: (file: File) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        alert('Файл слишком большой! Размер файла не должен превышать 1 МБ.');
      } else {
        setSelectedImage(file);
      }
    }
  }

  const handleUpload = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedImage) {
      onUpload(selectedImage);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="image-uploader">
      
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="fileInput"
        id="file"
      />
      {selectedImage ? <div className='photoCheck'></div> : <div className='photoCheck'>Фото не выбрано</div>}
      <button
        type="button"
        onClick={triggerFileInput}
        className="customFileButton"
      >
        Выбрать&nbsp;файл
      </button>
      <button type="button" onClick={handleUpload} disabled={!selectedImage} className='customLoadButton'>
  Загрузить
</button>
      {/* <button type="button" onClick={handleUpload} disabled={!selectedImage} className='customLoadButton'>
        Загрузить
      </button> */}
      
    </div>
  );
};

export default ImageUploader