export function getCookie(name) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  
  export function setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options,
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value ?? '');
  
    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
  }
  
  export function deleteCookie(name) {
    setCookie(name, '', {
      'max-age': -1,
    });
  }
  
  export const changeTokenCookies = (cookie, needDelete = false) => {
    if (needDelete) {
      deleteCookie('accessToken');
      deleteCookie('refreshToken');
      return;
    }
    setCookie('accessToken', cookie.accessToken);
    setCookie('refreshToken', cookie.refreshToken);
  };
  