import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "./CommentSection.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLikeArticles,
  postLikeArticles,
} from "../../models/Articles/Articles.actions";
import { articlesItems } from "../../models/Articles/Articles.selectors";

interface IComment {
  id: number;
  username: string;
  text: string;
  timestamp: string;
  created_at: number;
  user: { username: string; email: string };
  likesCnt: number;
  likes: {
    user_id: number;
    id: number;
    post_id: number;
    article_id: number;
    created_at: number;
  }[];
}

interface ICommentProps {
  comment: IComment;
  userId: number;
  data: any;
}

const Comment: React.FC<ICommentProps> = ({ comment, userId, data }) => {
  const dispatch = useDispatch();
  const liked = comment.likes?.some((like) => like.user_id === userId);
  const likeButton = liked ? "trueLike" : "falseLike";

  const newLike = {
    id: userId,
    article_id: data.id,
    post_id: comment.id,
  };

  const handleLike = () => {
    if (!liked) {
      dispatch(postLikeArticles({ newLike, post: comment.id }));
    }
    if (liked) {
      dispatch(
        deleteLikeArticles({
          like: comment.likes.find((like) => like.user_id === userId)?.id,
          post: comment.id,
        })
      );
    }
  };

  return (
    <div className="comment">
      <div className="usersComment">
        <div className="userInfo">
          <div className="emailNameBlock">
            <span className="email">{comment.user?.email}</span>
            <span className="userName">{comment.user?.username}</span>
          </div>
          <span className="timestamp">
            <Moment unix format="DD.MM.YYYY / HH:mm">
              {comment.created_at}
            </Moment>
          </span>
        </div>
        <p className="text">{comment?.text}</p>
      </div>
      <div className="reactions">
        <p className="wavyArr"></p>
        <div className="likes">
          <button className={likeButton} onClick={handleLike}></button>
          <p className="likeCount">{comment?.likesCnt}</p>
        </div>
        <p className="alert"></p>
      </div>
    </div>
  );
};

export default Comment;
