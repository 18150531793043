import React, { useState } from "react";
import "./newBurgerMenu.scss";
import { Link } from "react-router-dom";

interface BurgerMenuProps {
  children: React.ReactNode;
}

export const NewBurgerMenu: React.FC<BurgerMenuProps> = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    window.scrollTo(0, 0);
  };

  return (
    <div className="burger">
      <button className="burger__button" onClick={toggleMenu}>
        &#9776;
      </button>
      <div
        className={`overlay${menuOpen ? " open" : ""}`}
        onClick={() => setMenuOpen(false)}
      ></div>
      <div className={`burger__menu${menuOpen ? " open" : ""}`}>
        <button
          className="burger__menu__closeButton"
          onClick={() => setMenuOpen(false)}
        >
          {/* &times; */}
        </button>
        <p className="burger__menu__closeButton--text">закрыть</p>
        <p className="burger__menu__closeButton--eye"></p>
        <p className="burger__menu__closeButton--bord"></p>
        <div className="burger__menu__body">
          <ul className="burger__menu__body__list">
            <li>
              <Link to="/profile" onClick={() => setMenuOpen(false)}>Профиль</Link>
            </li>
            <li>
              <Link to="/main" onClick={() => setMenuOpen(false)}>Главная</Link>
            </li>
            <li>
              <Link to="/missions" onClick={() => setMenuOpen(false)}>Миссии</Link>
            </li>
            <li>
              <Link to="/blog" onClick={() => setMenuOpen(false)}>Блог</Link>
            </li>
            <li>
              <Link to="/collections" onClick={() => setMenuOpen(false)}>Коллекции</Link>
            </li>
          </ul>
          <ul className="burger__menu__body__list">
            <li>FAQ</li>
            <li>БАЗА ЗНАНИЙ</li>
            <li>ПОЛИТИКА КОНФЕДИЦИАЛЬНОСТИ</li>
            <li>ЛИЦЕНЗИЯ</li>
          </ul>
        </div>
      </div>
    </div>
  );
};