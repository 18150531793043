import React from "react";

export type PublicRoute = {
  path: PublicRoutesNames;
  element: React.ComponentType;
};

export type PrivateRoute = {
  path: PrivateRoutesNames;
  element: React.ComponentType;
};

export enum PublicRoutesNames {
  HOME_UNSIGN = "/",
  REG = "/reg",
  LOGIN = "/login",
  PROFILE = "/profile",
  MIS = "/mission",
  RESTOREPASS = "/restorepass",
  REGNEWPASS = "/reset-password",
  NOTFOUND = "/not-found",
  MISSIONS = "/missions",
  COLLECTPAGE = "/collections",
  BLOG = "/blog",
  MAIN = "/main",
  COLLECTION = "/collection/:id/:slug",
}

export enum PrivateRoutesNames {
  MAIN = "/main",
  HOME = "/",
  COLLECTION = "/collection/:id/:slug",
  PROFILE = "/profile",
  CHANGE = "/changeuser",
  NOTFOUND = "/not-found",
  MISSIONS = "/missions",
  COLLECTPAGE = "/collections",
  BLOG = "/blog",
  TEST = "/test",
}
