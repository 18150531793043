import { all } from "redux-saga/effects";
import watchArticlesItems from "../models/Articles/Articles.saga";
import watchUserAuth from "../models/UserAuth/userAuth.saga";
import watchUserInfoData from "../models/UserInfo/userInfo.saga";

export default function* rootSaga() {
  yield all([
    watchUserAuth(),
    watchArticlesItems(),
    watchUserInfoData(),
  ]);
}
