import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../models/UserAuth/userAuth.actions";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import { getUserInfoRequest } from "../../models/UserInfo/userInfo.actions";
import { userInfoDataValues } from "../../models/UserInfo/userInfo.selectors";
import { BurgerMenu } from "../burgerMenu/BurgerMenu";
import { Button } from "../UI/button/Button";
import "./header.scss";
import { NewBurgerMenu } from "../newBurgerMenu/NewBurgerMenu";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthValue = useSelector(isAuth);
  const userDataValue = useSelector(userInfoDataValues);

  const sendLogOut = () => {
    dispatch(userLogout());
    navigate("/");
  };

  useEffect(() => {
    if (isAuthValue) {
      dispatch(getUserInfoRequest());
    }
  }, [isAuthValue]);

  return (
    <div className="header-nav container">
      <div className="header-nav__Menu">
        <NewBurgerMenu />
        <div className="header-nav__Menu--menu">
          <p>МЕНЮ</p>
        </div>
      </div>
      {isAuthValue ? (
        <img
          src={`${
            userDataValue?.avatar_base_url + "/" + userDataValue?.avatar_path
          }`}
          className="header-nav__People--photo"
        />
      ) : (
        <p></p>
      )}
      {isAuthValue ? (
          
          <div>
            <div className="header-nav__People">
              <p className="header-nav__People--logo-symbol"></p>
              <p className="header-nav__People--oval"></p>

              {/* {`overlay${menuOpen ? " open" : ""}`} */}
            </div>
            <div className="header-nav__Wallet">
              <p className="header-nav__Wallet--price">
                <span className="header-nav__Wallet--price-span">
                  {isAuthValue ? userDataValue?.balance : "кошелька нет"}
                </span>
                YJT
              </p>
              <div className="header-nav__Wallet__buttons">
                <Button
                  text={"Подключить кошелёк"}
                  className={"button header-nav__Wallet__buttons--button"}
                  onClick={() => alert("подключен)")}
                  disabled={false}
                  type={"button"}
                  submit={false}
                >
                  Подключить кошелёк
                </Button>

                <Button
                  text={"выйти"}
                  className={"button header-nav__Wallet__buttons--button"}
                  onClick={() => sendLogOut()}
                  disabled={false}
                  type={"button"}
                  submit={false}
                ></Button>
              </div>
            </div>
          </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};
