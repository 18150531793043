import axios from "axios";
import { getCookie, setCookie } from "../utils/cookie";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

api.interceptors.request.use((config) => {
  const accessToken = getCookie("accessToken");
  // console.log("Access Token (request interceptor):", accessToken);

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error?.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const sentRefreshToken = await api.post("refresh-token", {
          refreshToken: getCookie("refreshToken"),
        });
        console.log(
          "Server response (response interceptor):",
          sentRefreshToken.data
        );
        console.log(
          "New Access Token (response interceptor):",
          sentRefreshToken.data.tokens.accessToken
        );
        setCookie("accessToken", sentRefreshToken.data.tokens.accessToken);
        setCookie("refreshToken", sentRefreshToken.data.tokens.refreshToken);
        originalRequest.headers["Authorization"] = getCookie("accessToken");
        return api.request(originalRequest);
      } catch (e) {
        console.error("Error in response interceptor:", e);
        return e;
      }
    }
    throw error;
  }
);