export const USER_INFO_FETCHING = 'USER_INFO_FETCHING'
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS'
export const USER_INFO_ERROR = 'USER_INFO_ERROR'

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST'


export const userInfoFetching = () => ({
    type: USER_INFO_FETCHING,
})
export const userInfoSuccess = data => ({
    type: USER_INFO_SUCCESS,
    payload: data
})
export const userInfoError = error => ({
    type: USER_INFO_ERROR,
    payload: error
})

export const getUserInfoRequest = () => ({
    type: GET_USER_INFO_REQUEST,
})