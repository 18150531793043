import { FC } from "react";
import { Menu } from "../menu/Menu";
import { Formik, Field, Form } from "formik";
import { FormTitle } from "../formTitle/FormTitle";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../models/UserAuth/userAuth.actions";
import { useNavigate } from "react-router-dom";
import { Button } from "../UI/button/Button";
import { Loader } from "../UI/loader/Loader";
import { isFetching } from "../../models/UserAuth/userAuth.selectors";
import { FooterNav } from "../FooterNav/FooterNav";
import "./loginForm.scss";

type LoginUser = {
  username: string;
  password: string;
};

export const LoginForm: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const feching = useSelector(isFetching);

  const initialValues: LoginUser = {
    username: "",
    password: "",
  };

  const sendLoginRequest = (values: any) => {
    dispatch(userLogin(values));
    {
      feching && <Loader />;
    }
    navigate("/main");
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={"SignupSchema"}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          sendLoginRequest(values);
        }}
      >
        {({}) => (
          <Form>
            <div className="registration container">
              <p className="registration__left-decor"></p>
              <Menu />
              <img
                src="/assets/image/auth/log_pharaon.png"
                className="registration--photo"
                alt="picture"
              />

              <p className="registration--photoMobile"></p>

              <div className="registration__form">
                <div className="registration__form--head">
                  <FormTitle title="Вход в личный кабинет" />
                </div>

                <div className="registration__form__layout">
                  <div className="registration__form__layout__right">
                    <p className="registration-text">Логин / телефон / Email</p>
                    <Field className="registration-input" name="username" />
                    <p className="registration-text">Пароль</p>
                    <Field className="registration-input" name="password" />
                  </div>
                </div>
                {/* navigate("/restorepass") */}
                <span className="bord"></span>
                <div className="registration__form__layout__middle bord">
                  <span className="forget-info">
                    Забыли пароль? Его можно{" "}
                    <a href="/restorepass">восстановить</a>
                  </span>
                </div>

                <div className="registration__form__layout ">
                  <div className="registration__form__layout__right">
                    <Button
                      className="buttonConfirm "
                      text={"Войти"}
                      type={"submit"}
                      disabled={false}
                      submit
                      onClick={() => {}}
                    ></Button>
                    <p className="arrButton"></p>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <FooterNav />
    </div>
  );
};
