import { memo, FC } from "react";
import Moment from "react-moment";
import "./articleSmallCards.scss";

const defaultPic= 'https://flyclipart.com/thumbs/goat-simulator-ios-all-goats-967802.png'
type ArticlesCardTypes = {
  src: string;
  onClick: () => void;
  title:string;
  date:string;
  difficulty:number;
};

export const ArticleSmallCards: FC<ArticlesCardTypes> = memo(({ src=defaultPic, onClick,title, date, difficulty }) => {
  // const SortOfStars = (difficulty: any) => {
  //   let stars = []
  //     const lightStars = (difficulty) => {
  //       for (let i = 0; i < difficulty; i++) {
  //         stars.push(<p></p>)
  //       }
  //     }
    
  //     const darkStars = (difficulty) => {
  //       for (let j = difficulty; j < 5; j++) {
  //         stars.push(<p className="darkStars"></p>)
  //       }
  //       return stars
  //     }
    
  //     return (
  //       <div className="cardsOfMissions__card__group__element2rigth--stars">
  //         {lightStars(difficulty)}
  //         {darkStars(difficulty)}
  //       </div>
  //     );
  //   };

  return (
    <div className="cardsOfMissions">  
      <div className="cardsOfMissions__card">
        <img
          role="presentation"
          className={"cardsOfMissions__card--img"}
          onClick={onClick}
          src={src}
          alt=""
        />
        <div className="cardsOfMissions__card--text">Бойня за токен времени Бойня за токен времени Бойня за токен времени</div>
        <div className="cardsOfMissions__card__group">
          <div className="cardsOfMissions__card__group__element1left">
            <p className="cardsOfMissions__card__group__element1left--head">
              /New
            </p>
            <p className="cardsOfMissions__card__group__element1left--data">
              <Moment unix format="YYYY DD.MM">{date}</Moment>
            </p>
          </div>
          <div className="cardsOfMissions__card__group__element2rigth">
          {/* {
           SortOfStars(difficulty)
          } */}
            <p className="cardsOfMissions__card__group__element2rigth--arrow" onClick={onClick}></p>
          </div>
        </div>
      </div>
    </div>
  );
});