import { FC } from "react"
import { Link } from "react-router-dom"
import "./regTitle.scss"

type TitleNameProps = {
  title: string
}

export const FormTitle: FC<TitleNameProps> = ({title}) => {
  return (
    <div className="form-title" >
      <Link className="form-title__main" to="/main">Главная</Link>
      <span className="form-title__slash">/</span>
      <span className="form-title--reg-of-user">{title}</span>
    </div>
  )
}


{/* <Link className="nav-menu--item" to="/main">
          Главная
        </Link> */}