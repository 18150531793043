import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { FormTitle } from "../../components/formTitle/FormTitle";
import { changeUser } from "../../models/UserAuth/userAuth.actions";
import { isAuth } from "../../models/UserAuth/userAuth.selectors";
import {
  isFetching,
  userInfoDataValues,
} from "../../models/UserInfo/userInfo.selectors";
import { getUserInfoRequest } from "../../models/UserInfo/userInfo.actions";
import { api } from "../../core/api";
import ImageUploader from "../../components/UI/imageUploader/ImageUploader";
import { Loader } from "../../components/UI/loader/Loader";
import { Button } from "../../components/UI/button/Button";
import CheckboxGroup from "../../components/CheckboxGroup/CheckboxGroup";
// import { RegistrationSchema } from "../../utils/validation/validationSchemes";
// import { userInfoData } from "../../models/UserInfo/userInfo.reducer";
import "./changeProfilePage.scss";

type RegustrationUser = {
  firstname: string;
  lastname: string;
  wallet: string[];
  about: string;
  notification: string;
  agree: [];
};

export const ChangeProfilePage: FC = () => {
  const isAuthValue = useSelector(isAuth);
  const userDataValue = useSelector(userInfoDataValues);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetching = useSelector(isFetching);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthValue) {
        await dispatch(getUserInfoRequest());
      }
    };

    fetchData();
  }, [isAuthValue, dispatch]);

  useEffect(() => {
    setAvatarUrl(
      userDataValue
        ? `${userDataValue.avatar_base_url}/${userDataValue.avatar_path}`
        : null
    );
  }, [userDataValue]);

  const handleImageUpload = async (image: File) => {
    const formData = new FormData();
    formData.append("file", image);

    try {
      await api.post("/user-add-avatar", formData);
      await dispatch(getUserInfoRequest());
      setAvatarUrl(
        `${
          userDataValue?.avatar_base_url + "/" + userDataValue?.avatar_path
        }?t=${new Date().getTime()}`
      );
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const initialValues: RegustrationUser = {
    firstname: userDataValue?.firstname || "",
    lastname: userDataValue?.lastname || "",
    wallet: userDataValue?.wallet ? userDataValue.wallet.split(", ") : [],
    // wallet: userDataValue?.wallet || [],
    about: userDataValue?.about || "",
    notification: userDataValue?.notification || "",
    agree: userDataValue?.agree || [],
  };

  if (fetching) {
    return <Loader />;
  }

  const sendChangeUserRequest = (values: any) => {
    const checkedWallets = document.querySelectorAll(
      'input[name="wallet"]:checked'
    );
    const walletArray = Array.from(checkedWallets).map(
      (checkbox: any) => checkbox.value
    );
    const cleanedWallet = walletArray.join(", ");

    const data = {
      firstname: values.firstname,
      lastname: values.lastname,
      about: values.about,
      wallet: cleanedWallet,
      notification: values.notification,
      password: values.password,
    };
    dispatch(changeUser(data));
    navigate("/main");
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={RegistrationSchema}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(false);
        sendChangeUserRequest(values);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="registration">
            <p className="registration__left-decor"></p>
            <img
              src="/assets/image/auth/reg_pharaon.png"
              className="registration--photo"
              alt="something"
            />

            <div className="registration__form">
              <FormTitle title="РЕДАКТИРОВАНИЕ ПОЛЬЗОВАТЕЛЯ" />

              <div className="registration__form__layout">
                <p className="registration__form__layout__left">01 /</p>

                <div className="registration__form__layout__right">
                  <p>Фамилия</p>
                  <Field className="registration-inputChange" name="lastname" />
                  <p>Имя</p>
                  <Field
                    className="registration-inputChange"
                    name="firstname"
                  />
                  <p>Новый пароль</p>
                  <Field className="registration-inputChange" name="password" />
                  <p>Подтверждение нового пароля</p>
                  <Field
                    className="registration-inputChange"
                    name="passwordConfirm"
                  />
                </div>
              </div>
              <div className="registration__form__layout__right--photo">
                <h1>Сменить фото профиля</h1>
                <ImageUploader onUpload={handleImageUpload} />
                <img
                  src={
                    avatarUrl ||
                    `${
                      userDataValue?.avatar_base_url +
                      "/" +
                      userDataValue?.avatar_path
                    }`
                  }
                  className="avatar"
                  alt="something"
                />
              </div>
              <div className="registration__form__layout">
                <p className="registration__form__layout__left">02 /</p>
                <div className="registration__form__layout__middle"></div>

                <div className="registration__form__layout__right">
                  <span className="text2">
                    Выбериете кошельки, которые вы планируете использоввать.
                    Если затрудняетесь, можете оставить поле пустым.
                  </span>
                  </div>
                  <div className="registration__form__layout__right--checkboxes-list">
                    <CheckboxGroup
                      name="wallet"
                      value={values.wallet}
                      onChange={(newValue: string[]) =>
                        setFieldValue("wallet", newValue)
                      }
                      options={[
                        "Bitcoin BTC",
                        "XRP XRP",
                        "BNB BNB",
                        "Litecoin LTC",
                        "Ethereum ETH",
                        "Cardano ADA",
                        "Zcash ZEC",
                      ]}
                    />
                  </div>
                
              </div>

              <div className="registration__form__layout">
                <p className="registration__form__layout__left">03 /</p>
                <span></span>
                <div className="registration__form__layout__right">
                  <p>Описание профиля</p>
                  <Field
                    as="textarea"
                    className="registration__form__layout__right reg-text-area "
                    name="about"
                    placeholder="Пару слов о себе..."
                  />
                </div>
              </div>

              <div className="registration__form__layout">
                <span className="registration__form__layout__left">04 /</span>
                <span className="registration__form__layout__middle"></span>

                <div className="registration__form__layout__right__notifications">
                  <p>Хотите получать уведомления о транзакциях?</p>
                  <div className="registration__form__layout__right__notifications__radio-btns">
                    <label>
                      <Field type="radio" name="notification" value="later" />
                      &nbsp;&nbsp;&nbsp;Решить потом
                    </label>

                    <label>
                      <Field type="radio" name="notification" value="yes" />
                      &nbsp;&nbsp;&nbsp;Да
                    </label>

                    <label>
                      <Field type="radio" name="notification" value="no" />
                      &nbsp;&nbsp;&nbsp;Нет
                    </label>
                  </div>
                </div>
              </div>

              <span className="bord"></span>

              <div className="registration__form__layout ">
                <div className="registration__form__layout__right__buttons">
                  <Button
                    className="reg-submitChange reg-button"
                    text="Изменить данные"
                    type="submit"
                    submit
                  ></Button>
                  <p className="reg-submitChange--arr"></p>
                  <Button
                    onClick={() => navigate(-1)}
                    className="reg-submitChange reg-button"
                    text="Отменить"
                  ></Button>
                  <p className="reg-submitChange--arr2"></p>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
