import { api } from "../../core/api";



export const getUserInfoRequest = async () => api.get('/users');


export const createLibrariesItemsRequest = async data => api.post('/items', data);


export const passwordResetRequest = async (data) => api.post("/password-reset", data);
export const changeRequest = async (data, id) => api.put(`/users/0`, data);


