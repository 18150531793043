import { FC, PropsWithChildren, } from "react";
import cn from 'classnames';
import "./button.scss";

type PropTypes = {
  text?: string;
  className?: string;
  onClick?: ()=>void;
  disabled?: boolean;
  type?: any,
  submit?: boolean,
};


export const Button: FC<PropsWithChildren<PropTypes>> = (
  ({ text, className, onClick = ()=>{},  disabled=false, type='button', submit }) => {
    return (
      <button
      type = {submit ? 'submit' : 'button'}
        className={className}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
);
