import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegistrationSchema = Yup.object().shape({
  lastname: Yup.string()
    .min(4, "Слишком коротко!")
    .max(50, "Слишком длинно!")
    .required("Это поле должно быть заполнено"),
  firstname: Yup.string()
    .min(2, "Слишком коротко!")
    .max(50, "Слишком длинно!")
    .required("Это поле должно быть заполнено"),
  phone: Yup.string().matches(phoneRegExp, "Телефон неправильный"),
  email: Yup.string()
    .email("Не правильный email")
    .required("Это поле должно быть заполнено"),
  password: Yup.string()
    .required("Это поле должно быть заполнено.")
    .min(8, "Пароль должен быть от 8 символов.")
    .matches(/[a-zA-Z]/, "Пароль должен быть только на латиннице."),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password")],
    "Пароли не совпадают"
  ),
  // agree: Yup.boolean().oneOf([true], 'Accept Terms & Conditions is required')
});

//

export const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});
