import React from "react";
import "./yadget.css";

const HTMLContent = () => {
  const htmlContent = `
<body>
    <div class="wrapper">

        <article class="textAndImages">
            <p class="articleText">
Время... Куда ты постоянно бежишь... Порой откроешь глаза, а Солнце уже на закате. А иногда чувствуешь себя так необыкновенно, 
как будто Времени совсем не существовало раньше - оно так быстро пролетело, как будто его и не было никогда.<br><br>

Время течёт и оно не обратимо движет всю материю трансформироваться, меняться, совершенствоваться.<br><br>
                
Время — бесконечная конкурентная игра, в котором ты либо бежишь, либо падаешь.<br><br>

Но разница между бегом и падением иллюзорна - время всё равно тикает для всех одинаково, вопрос лишь в том, как часто мы смотрим на циферблат. 
            </p>
            <img class="articleImageFull" src="/assets/img/telegram-cloud-photo-size-4-5939357914424782670-w.png" alt="gears">
            <p class="articleTextComment">
Есть прошлое, которое не вернуть.<br><br>
                
Есть будущее, которое ещё не наступило.<br><br>
                
Есть настоящее, которое происходит всегда в любой момент времени здесь и сейчас. 
            </p>
            <img class="articleImage" src="/assets/img/telegram-cloud-photo-size-4-5939520345792950094-y.png" alt="train_time">
            <p class="articleText">
Прошлое удивляет нас тем, что оно как всё вокруг воспринимается и остаётся в памяти, но дело в том, 
что память порой может быть обманчивой и то, что необходимо забывает, а то, что нужно - запоминает в нужном свете.<br><br>
                
Тот, кто живёт мыслями о прошлом - забывает жить здесь и сейчас. <br><br>
        
Будущее вдохновляет нас тем, что оно как всё вокруг воспринимается образами и движет нас к Свету, Надежде, Любви, 
которое невозможно описать словами, символами, цифрами - все инструменты описания будущего такие же субъективные, как воспоминания о прошлом. 
Лишь претворив что-то в Явъ, мы потом можем сказать, насколько мы приблизились к тому Будущему, которое когда-то воображали у себя в образах.<br><br>
        
Важно понимать, что Будущее - это образ того состояния, которое хотим чувствовать. А Ум и Интеллект уже находят инструменты достижения этого состояния.<br><br>
        
Тот, кто живёт мыслями о будущем - начинает жить здесь и сейчас. Ключевое «Начинает». Но это не значит, что он живёт здесь и сейчас. 
Он может бесконечно много чего начинать, но к будущему это может не иметь никакого отношения. Важно то, что будет доведено до состояния. 
            </p>
            <img class="articleImage" src="/assets/img/telegram-cloud-photo-size-4-5939297578724208395-w.png" alt="drops">
            <p class="articleText">
Настоящее прекрасно тем, что проверяет на прочность наши иллюзии Прошлого и Будущего... Появляются факты, которые ставят под сомнения Историю.<br>
Появляются технологии, которые преображают образ Будущего. Происходят события, которые меняют всё, созданное его величеством Умом.
            </p>
        </article>

        <article class="additionInfo">
            <h1 class="additionInfo_head">
                Настоящее - <br> это игра
            </h1>
            <div class="underHead">
            <p class="additionInfo_headMini">
                между Иллюзиями, которая своим танцем позволяет чувствовать жизнь всеми клеточками тела
            </p>
            <img class="additionInfo_img" src="/assets/img/telegram-cloud-photo-size-4-5941563693663891278-w.png" alt="sandwatch">
        </div>
        </article>

        <article class="descrioptionOfArticles">
            <p class="articleText">
                Этой Коллекцией я попытался передать ЦЕННОСТЬ ВРЕМЕНИ и оно будет проявляться тем, кто будет им обладать. У каждого из нас есть ВРЕМЯ. 
                Но кто как его использует, а кого использует само ВРЕМЯ - вот где золотая середина скрыта.
            </p>
            <div class="article_question">
                
                <p class="article_questionText">Что сделает эти <span>токены</span> дороже Золота?</p>
                <div class="article_question_cards">
                    <p class="articleTextNew">
                        Чтобы ответить на этот вопрос, сначала посмотрим на характеристики Коллекции:
                    </p>
                    <div class="article_question_card">
                        <p class="cardNumber">101</p>
                        <p class="cardText">анимация</p>
                    </div>
                    <div class="article_question_card">
                        <p class="cardNumber">2 651</p>
                        <p class="cardText">токенов</p>
                    </div>
                </div>
            </div>
            <div class="article_question_cards2">
                <p class="articleTextNew">
                    Анимации имеют разную эмиссию токенов. Анимация показывает Песочные часы, где частицы стремятся к середине.
                </p>
                <div class="article_question_card2">
                    <p class="cardNumber2">50</p>
                    <p class="cardText2">анимаций «Прошлого»</p>
                    <p class="cardText3">которые стремятся к настоящему</p>
                </div>
                <div class="article_question_card2">
                    <p class="cardNumber2">50</p>
                    <p class="cardText2">анимаций «Будущего»</p>
                    <p class="cardText3">которые стремятся к настоящему</p>
                </div>
                <div class="article_question_card2">
                    <p class="cardNumber2"> 1</p>
                    <p class="cardText2">анимация «Сейчас»</p>
                    <p class="cardText3">середина между колбами Часов</p>
                </div>
            </div>

            <div class="article_question">
                <div class="article_questionGroup">
                    <p class="article_questionText2">Эмиссия токенов идёт по определенной закономерности в виде <span>песочных 
                        часов</span>:</p>
                    <img class="article_question_img" src="/assets/img/telegram-cloud-photo-size-4-5939655899255779189-w.png" alt="sandtoken">
                </div>
                <div class="article_question_cards">
                    <p class="articleTextNew">
                        Тот, кто будет следить за ВРЕМЕНЕМ, тот будет понимать, какие токены ценней других и будет побеждать. <br><br>
                        Тот, кто будет относиться к ВРЕМЕНИ не внимательно, тот будет ошибаться и его время будет сыпаться как песок сквозь пальцы.
                    </p>
                    
                    <div class="article_question_table">
                        <p class="cardTextTable">
                            #1 Прошлое — 51 токен<br><br>
                            #2 Триллионы лет назад — 50 токенов <br><br>
                            <span>
                                ...<br><br>
                                ...<br><br>
                            </span> 
                            #47 Минута назад — 5 токенов<br><br>
                            <span>
                                ...<br><br>
                            </span>
                            #51 Сейчас — 1 токен<br><br>
                            <span>
                                ...<br><br>
                            </span>
                            #63 18 часов вперед — 13 токенов.<br><br>
                            <span>
                                ...<br><br>
                                ...<br><br>
                            </span> 
                            #100 Триллионы лет вперед — 50 токенов.<br><br>
                            #101 Будущее — 51 токенов<br>
                        </p>
                    </div>
                    
                </div>
                <p class="articleText">
                    Тот, кто будет следить за ВРЕМЕНЕМ, тот будет понимать, какие токены ценней других и будет побеждать. <br><br>
                    Тот, кто будет относиться к ВРЕМЕНИ не внимательно, тот будет ошибаться и его время будет сыпаться как песок сквозь пальцы.
                </p>

                <p class="articleTextBig">
                Ценность токенов Времени будет формироваться следующими условиями
            </p>
            <ol class="articleList custom-counter">
                <p class="bigList">
                    Токены можно использовать на благо себе, но при использование токен улетает на адрес, с которого он уже никогда не вернется в обращение.
                    <div class="bigListTable">
                        <p class="adressTable">Адрес отправки </p>
                        <p class="adress">0x000000000000000000000000000000000000dEaD</p>   
                    </div>
                </p>
                <p class="bigList">Токены ближе к середине с меньшей эмиссией, чем к крайностям Прошлого и Будущего - своей ограниченностью ценней будет те, каких меньше.</li>
                <p class="bigList">В Метавселенной Уаджета есть два статуса:
                    <ol class="custom-counter">
                        <p class="smallList">Хранитель Времени будет всеми силами сохранять токены, избегая искушения использовать их на благо себе.</li>
                        <p class="smallList">Похититель Времени будет использовать токены себе на благо и «охотиться» за Хранителями Времени, чтобы забрать у них токены и использовать.</li>
                    </ol>
                </li>
            </ol>
                <p class="articleTextBig">
                    Официальный канал информирования об использование токенов Времени
                </p>
                <div class="cardPerson_group">
                    <div class="cardPerson">
                        <img class="cardPerson_img" src="/assets/img/Bitmap Copy 3.png" alt="sendweight">
                        <p class="cardPerson_name">@vremya_10_17</p>
                        <p class="cardPerson_link">Ссылка ТГ</p>
                        <button class="buttonCard"></button>
                        <p class="arrCard"></p>
                    </div>
                    <img class="cardPerson_loop" src="/assets/img/telegram-cloud-photo-size-4-5939406494799867710-y.png" alt="scaletime">
                </div>
                
                <p class="articleTextBig">
                    Время, Психология Людей и Искушение управлять временем — приведет к 
                    исчезновению токенов Коллекции и создания ограниченной ценности в 
                    Метавселенной Уаджета
                </p>
                <img class="articleImageFull" src="/assets/img/telegram-cloud-photo-size-4-5939617768536125251-w.png" alt="singular">
            </div>

            <article class="infoHeader">
                <h1 class="infoHeader_head">
                    2 651
                </h1>
                    <p class="infoHeader_headMini">
                        Коллекция ВРЕМЯ перестанет существовать, когда все 2651 будут использованы и отправлены в БЕСКОНЕЧНОСТЬ
                    </p>
            </article>

            <p class="articleText">
                Хранители должны сохранить ВРЕМЯ, так как без времени не может существовать сама жизнь. <br> <br>
                Похитители сделают всё возможное, чтобы развеять Иллюзию существования самого ВРЕМЕНИ. <br> <br>
                Это Коллекция превратится в Драгоценность, вопрос лишь ВРЕМЕНИ и количества умов, увлечённых на той или иной стороне.<br> <br>
                Соревнование двух Сил придадут противодействующим токенам Ценность, а моменту Сейчас — бесценность и напомнит всем о силе времени!
            </p>
            <p class="articleTextBig">
                Расскажи об это Коллекции своим друзьям и пусть они получат удовольствие от противостояние двух сторон! 
            </p>
            <img class="articleImage" src="/assets/img/telegram-cloud-photo-size-4-5939145863299444540-y.png" alt="train_time">
        </article>
    </div>


</body>
</html>
    `;

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default HTMLContent;
