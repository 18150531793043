import {
  ARTICLES_FETCHING,
  ARTICLES_SUCCESS,
  ARTICLES_ERROR,
  ARTICLES_POST_SUCCESS,
  ARTICLES_LIKE_SUCCESS,
} from "./Articles.actions";

const initialState = {
  data: {},
  fetching: false,
  error: "",
};

export const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case ARTICLES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
      };

    case ARTICLES_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    case ARTICLES_POST_SUCCESS:
      return {
        ...state,
        data: addPostsReducer(state.data, action.payload.data),
        fetching: false,
      };

    case ARTICLES_LIKE_SUCCESS:
      return {
        ...state,
        data: changeLikesReducer(state.data, action.payload.data),
        fetching: false,
      };

    default:
      return state;
  }
};

const addPostsReducer = (data, post) => {
  const newData = JSON.parse(JSON.stringify(data));

  for (let key in newData) {
    const article = newData[key].find(
      (article) => article.id == post.article_id
    );
    if (article) {
      article.posts.push(post);
    }
  }
  return newData;
};

const changeLikesReducer = (data, post) => {
  const newData = JSON.parse(JSON.stringify(data));

  for (let key in newData) {
    const article = newData[key].find(
      (article) => article.id == post.article_id
    );
    if (article) {
      const targetPost = article.posts.find((e) => e.id == post.id);
      if (targetPost) {
        targetPost.likes = post.likes;
        targetPost.likesCnt = post.likesCnt
      }
    }
  }
  return newData;
};
