import { FC } from "react";
import { Formik, Field, Form } from "formik";
import { RegistrationSchema } from "../../utils/validation/validationSchemes";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { userRegistration } from "../../models/UserAuth/userAuth.actions";
import { Menu } from "../../components/menu/Menu";
import { FormTitle } from "../../components/formTitle/FormTitle";
import "./setNewPasswordPage.scss";
import { Button } from "../../components/UI/button/Button";
// import { isFetching } from "../../../models/UserAuth/userAuth.selectors";

type RegustrationUser = {
  password: string;
  passwordConfirm: string;
};

export const SetNewPasswordPage: FC = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParams = searchParams.get("token");

  const initialValues: RegustrationUser = {
    password: "",
    passwordConfirm: "",
  };

  const sendRegistrationRequest = (values: any) => {
    const data = {
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    };
    dispatch(userRegistration(data));
    alert("Пароль Сменен");

  };
   if (!tokenParams) return <p>перейдите по ссылке в письме</p>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegistrationSchema}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(false);
        sendRegistrationRequest(values);
      }}
    >
      {({ errors, touched, dirty, isValid }) => (
        <Form>
          <div className="registration">
            <p className="registration__left-decor"></p>
            <Menu />
            <img
              src="/assets/image/auth/reg_pharaon.png"
              className="registration--photo"
              alt="Нет изображения"
            />

            <div className="registration__form">
              <FormTitle title="Восстановления пароля" />

              <div className="registration__form__layout">
                <p className="registration__form__layout__left">01 /</p>
                <div className="registration__form__layout__middle">
                  <p>Пароль</p>
                  <p>Подтверждение пароля</p>
                </div>
                <div className="registration__form__layout__right">
                  <Field className="registration-input" name="password" />
                  <Field
                    className="registration-input"
                    name="passwordConfirm"
                  />
                </div>
              </div>

              <div className="registration__form__layout ">
                <span></span>
                <span></span>
                <div className="registration__form__layout__right">
                  <Button className="reg-submit" type="submit">
                    Восстановления пароля
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
