export const ARTICLES_FETCHING = "ARTICLES_FETCHING";
export const ARTICLES_SUCCESS = "ARTICLES_SUCCESS";
export const ARTICLES_ERROR = "ARTICLES_ERROR";
export const ARTICLES_POST = "ARTICLES_POST";
export const ADD_ARTICLES_LIKE_POST = "ADD_ARTICLES_LIKE_POST";
export const ARTICLES_POST_SUCCESS = "ARTICLES_POST_SUCCESS";
export const DELETE_ARTICLES_LIKE_POST = "DELETE_ARTICLES_LIKE_POST";
export const ARTICLES_LIKE_SUCCESS = "ARTICLES_LIKE_SUCCESS";

export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";

export const articlesFetching = () => ({
  type: ARTICLES_FETCHING,
});
export const articlesSuccess = (data) => ({
  type: ARTICLES_SUCCESS,
  payload: data,
});
export const articlesError = (error) => ({
  type: ARTICLES_ERROR,
  payload: error,
});
export const getArticlesRequest = () => ({
  type: GET_ARTICLES_REQUEST,
});

export const postArticles = (data) => ({
  type: ARTICLES_POST,
  payload: data,
});
export const articlesLikeSuccess = (data) => ({
  type: ARTICLES_LIKE_SUCCESS,
  payload: data,
});



export const postLikeArticles = (data) => ({
  type: ADD_ARTICLES_LIKE_POST,
  payload: data,
});
export const deleteLikeArticles = (data) => ({
  type: DELETE_ARTICLES_LIKE_POST,
  payload: data,
});



export const postArticlesSuccess = (data) => ({
  type: ARTICLES_POST_SUCCESS,
  payload: data,
});