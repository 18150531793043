import {
    USER_INFO_FETCHING,
    USER_INFO_SUCCESS,
    USER_INFO_ERROR,
    
  } from './userInfo.actions';


const initialState = {
    data: null,
    fetching: false,
    error: '',
  };
  
  export const userInfoData = (state = initialState, action) => {
    switch (action.type) {
      case USER_INFO_FETCHING:
        return {
          ...state,
          fetching: true,
        };
  
      case USER_INFO_SUCCESS:
        return {
          ...state,
          data: action.payload,
          fetching: false,
        };
  
      case USER_INFO_ERROR:
        return {
          ...state,
          error: action.payload,
          fetching: false,
        };
  
      default:
        return state;
    }
  };