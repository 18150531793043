import axios from "axios";
import { api } from "../../core/api";

export const loginRequest = async ({ username, password }) =>
  axios.get(process.env.REACT_APP_BASE_URL + "/getToken", {
    headers: { Authorization: "Basic " + btoa(username + ":" + password) },
  });
  export const refreshRequest = async (data) => api.post("/refresh-token", data);
  export const registrationRequest = async (data) => api.post("/users", data);

  export const passwordResetRequest = async (data) => api.post("/password-reset", data);
  
  

  export const changeRequest = async (data, id) => api.put(`/users/0`, data);







  // export const loginRequest = async data => api.get('/getToken', data);
// export const logoutRequest = async () => api.delete('???');
// export const registrationRequest = async ({ firstname, password, email,lastname, phone, about }) =>
//   axios.get(process.env.REACT_APP_BASE_URL + "/getToken", {
//     headers: { Authorization: "Basic " + btoa(firstname,password,email,lastname,phone,about) },
//   });
