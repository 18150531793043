import { FC } from "react";
import "./FooterNav.scss";

export const FooterNav = () => {
  return (
    <div className="footer-nav container">
      <p className="footer-nav__imgEye"></p>
      <p className="footer-nav__imgOval"></p>
      <p className="footer-nav__imgFigure"></p>
      <p className="footer-nav__br"></p>

      <div className="footer-nav__nav">
        <ul className="footer-nav__nav__list">
          <li className="footer-nav__nav__list--li span">О вселенной</li>
          <li className="footer-nav__nav__list--li">НОВОЕ</li>
          <li className="footer-nav__nav__list--li">ПОПУЛЯРНОЕ</li>
          <li className="footer-nav__nav__list--li">АКТУАЛЬНОЕ</li>
          <li className="footer-nav__nav__list--li">О ВСЕЛЕННОЙ</li>
          <li className="footer-nav__nav__list--li">КОНТАКТЫ</li>
        </ul>
        <ul className="footer-nav__nav__list">
          <li className="footer-nav__nav__list--li span">Помощь</li>
          <li className="footer-nav__nav__list--li">FAQ</li>
          <li className="footer-nav__nav__list--li">БАЗА ЗНАНИЙ</li>
          <li className="footer-nav__nav__list--li">
            ПОЛИТИКА КОНФЕДИЦИАЛЬНОСТИ
          </li>
          <li className="footer-nav__nav__list--li">ЛИЦЕНЗИЯ</li>
        </ul>
      </div>
    </div>
  );
};
