import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./navMenu.scss";

type Props = {
  onLinkClick?: (id: number) => void;
};

const NavMenu = ({ onLinkClick }: Props) => {
  // useEffect(() => {
  //   window.scrollTo(0, 400);
  // }, []);
  return (
    <div className="container">
      <div className="nav-menu">
        <Link className="nav-menu--item" to="/profile">
          Профиль
        </Link>
        <Link className="nav-menu--item" to="/main">
          Главная
        </Link>
        <Link
          className="nav-menu--item"
          to="/missions"
          onClick={() => onLinkClick?.(3)}
        >
          Миссии
        </Link>
        <Link
          className="nav-menu--item"
          to="/blog"
          onClick={() => onLinkClick?.(4)}
        >
          Блог
        </Link>
        <Link
          className="nav-menu--item"
          to="/collections"
          onClick={() => onLinkClick?.(5)}
        >
          Коллекции
        </Link>
      </div>
    </div>
  );
};

export default NavMenu;

// import React from "react";
// import useBreadcrumbs from "use-react-router-breadcrumbs";

//  const NavMenu = () => {
//   const breadcrumbs = useBreadcrumbs();

//   return (
//     <React.Fragment>
//       {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
//     </React.Fragment>
//   );
// };

// export default NavMenu;
